<template>
  <div class="Window positionr">
    <!-- banner -->
    <div class="bannerBox positionr">
      <img
        src="../../../assets/images/product/win/banner.png"
        alt=""
        width="100%"
        class="banner"
      />
      <div class="banner_contB positiona hp100">
        <div class="w1200 flex justify-end align-center hp100">
          <div class="banner_cont">
            <div class="f32">Windows客户端</div>
            <div class="f58 mt50 mb100">轻便好用，让业绩飞起来</div>
            <a href="https://pos.pluss.cn/resources/sdk/云蓝收银3.0.4.msi">
              <div class="downLoadBtn textc text-white f26 pointer transition">免费下载</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 云端同步 -->
    <div class="bg-white">
      <div class="w1200 flex align-center justify-between pt20 pb20">
        <div style="width: 420px" class="animated fadeInLeftBig">
          <div class="f20">云端同步</div>
          <div class="f40 mt30 mb40">断网断电都不怕</div>
          <div class="f16">
            云蓝收银采用革新性的网络同步技术，一旦遇到断网、停电、火灾、失窃等情况，将开启自动保护机制，状况结束后营业数据通过网络自动同步，再也不用担心意外造成的营业中断和
            数据丢失。
          </div>
        </div>
        <img
          src="../../../assets/images/product/win/model_1.png"
          alt=""
          class="animated fadeInRightBig"
        />
      </div>
    </div>
    <!-- 手机支付 -->
    <div class="model positionr text-white">
      <img src="../../../assets/images/product/win/model_2.png" alt="" class="modelImg"/>
      <div class="banner_contB positiona hp100">
        <div class="w1100 flex justify-end align-center hp100">
          <div style="width: 430px" class="animated fadeInRightBig">
            <div class="f20">手机支付</div>
            <div class="f40 mt30 mb40">深度集成多种支付方式</div>
            <div class="f16">
              云蓝收银系统同时支持Apple
              Pay、支付宝、微信、京东钱包和团购验证，让你一步到位，从此告别排队结账，顾客当
              然更爱来。
            </div>
            <div class="flex flex-wrap mt50">
              <div class="payItem flex align-center justify-center mr15 mb15">
                <img src="../../../assets/images/product/win/pay_1.png" alt="" />
              </div>
              <div class="payItem flex align-center justify-center mr15 mb15">
                <img src="../../../assets/images/product/win/pay_2.png" alt="" />
              </div>
              <div class="payItem flex align-center justify-center mb15">
                <img src="../../../assets/images/product/win/pay_3.png" alt="" />
              </div>
              <div class="payItem flex align-center justify-center mr15 mb15">
                <img src="../../../assets/images/product/win/pay_4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 外卖订单 -->
    <div class="bg-white">
      <div class="w1200 flex align-center justify-between pt20 pb20">
        <div style="width: 420px" class="animated fadeInLeftBig">
          <div class="f20">外卖订单</div>
          <div class="f40 mt30 mb40">无缝对接2大外卖平台</div>
          <div class="f16">
            <p>深度集成饿了么、美团外卖 ，用收银系统就能</p>
            <p>处理外卖订单直接到后厨，再也不用抱着手机前台后厨两</p>
            <p>边跑。</p>
          </div>
          <div class="mt15">
            <img
              src="../../../assets/images/product/win/elm.png"
              alt=""
              class="mr50"
            />
            <img src="../../../assets/images/product/win/meituan.png" alt="" />
          </div>
        </div>
        <img
          src="../../../assets/images/product/win/model_3.png"
          alt=""
          class="animated fadeInRightBig"
        />
      </div>
    </div>
    <!-- 全新界面 -->
    <div class="bg-gray">
      <div class="w1200 textc pt90">
        <div class="f20">全新界面</div>
        <div class="f40 mt30 mb40">快速上手，培训就免了吧</div>
        <div class="f16">
          <p>云蓝收银系统多平台统一设计，采用扁平化界面并重新设计功能布局，</p>
          <p>更支持触屏操作，为您快速提升收银效率。</p>
          <p>
            同时，云蓝收银系统支持旧系统数据快速迁移，从传统收银机转换再无不适感。
          </p>
        </div>
        <img
          src="../../../assets/images/product/win/model_4.png"
          alt=""
          class="pt90"
        />
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="bg-white">
      <div class="w1200 pt90 pb90">
        <div class="textc f32">应用场景</div>
        <div class="flex align-center justify-between mt60">
          <div class="textc">
            <div class="useItem">
              <img
                src="../../../assets/images/product/win/Mask_1.png"
                alt=""
                class="transition"
              />
            </div>
            <div class="f24 mt25 mb10">前台收银</div>
            <div class="f18">适合使用前台收银的门店</div>
          </div>
          <div class="textc">
            <div class="useItem">
              <img
                src="../../../assets/images/product/win/Mask_2.png"
                alt=""
                class="transition"
              />
            </div>
            <div class="f24 mt25 mb10">手机收银</div>
            <div class="f18">移动收银、点菜下单、卡券核销</div>
          </div>
          <div class="textc">
            <div class="useItem">
              <img
                src="../../../assets/images/product/win/Mask_3.png"
                alt=""
                class="transition"
              />
            </div>
            <div class="f24 mt25 mb10">平板收银</div>
            <div class="f18">平板点餐、平板收银</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商业及兼容性 -->
    <div class="bg-f9">
      <div class="w1200 pt90 pb90 textc">
        <div class="f40 mb40">商业级兼容性</div>
        <div class="f16">
          <p>
            当然您亦无需担心兼容问题，开店所需的小票机、扫码枪、钱箱、条码秤、打印
          </p>
          <p>机、 监控等设备全都已经为您兼容妥当。</p>
        </div>
        <img
          src="../../../assets/images/product/win/model_5.jpg"
          alt=""
          class="mt60"
        />
      </div>
    </div>
    <!-- 系统后台 -->
    <div class="bg-white">
      <div class="w1200 pt90 pb90 textc">
        <div class="f20">系统后台</div>
        <div class="f40 mt30 mb40">50+种报表，500强企业也能用</div>
        <div class="f16">
          <p>云蓝云后台内置门店所需的多种营业报表，</p>
          <p>
            从单店到连锁都能用，帮助您分析生意、进行连锁管理，并做出科学的决策。
          </p>
        </div>
        <div class="mt80 flex align-center justify-between">
          <div class="textc" style="width: 240px">
            <div class="flex align-center justify-center charts_tit">
              <span class="blocks bg_1 mr5"></span>
              <span class="mr5">销售额</span>
              <span class="blocks bg_2 mr5"> </span><span>利润</span>
            </div>
            <div>
              <div>
                <img src="../../../assets/images/product/win/chart_1.png" alt="" />
              </div>
              <div class="f16 fw600">业绩分析</div>
              <div class="text999 mt20">
                <p>哪一天业绩最好?</p>
                <p>哪个时段销售最旺?</p>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="textc" style="width: 240px">
            <div class="flex align-center justify-center charts_tit">
              <span class="blocks bg_1 mr5"></span>
              <span class="mr5">奶茶</span>
              <span class="blocks bg_2 mr5"> </span>
              <span class="mr5">甜点</span>
              <span class="blocks bg_1 mr5"></span>
              <span>套餐</span>
            </div>
            <div>
              <div>
                <img src="../../../assets/images/product/win/chart_2.png" alt="" />
              </div>
              <div class="f16 fw600">占比统计</div>
              <div class="text999 mt20">
                <p>哪个产品是爆款?</p>
                <p>支付宝、微信哪个用的多?</p>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="textc" style="width: 240px">
            <div class="charts_tit flex align-center justify-center">
              <div>
                <div><span class="mr5">•</span>新增会员137人，消费占比30%</div>
                <div><span class="mr5">•</span>历史会员823人，消费占比60%</div>
              </div>
            </div>
            <div>
              <div>
                <img src="../../../assets/images/product/win/chart_3.png" alt="" />
              </div>
              <div class="f16 fw600">会员页面</div>
              <div class="text999 mt20">
                <p>哪些会员最活跃?</p>
                <p>哪些会员不再来?</p>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="textc" style="width: 240px">
            <div class="flex align-center justify-between charts_tit">
              <div>
                <div class="f16 text-blue">179</div>
                <div class="f13">已发券</div>
              </div>
              <div>
                <div class="f16 text-blue">28</div>
                <div class="f13">已使用</div>
              </div>
              <div class="f12">
                <div class="flex align-center">
                  <span class="blocks bg_1 mr5"></span>
                  <span class="mr5">双11大促 40%</span>
                </div>
                <div class="flex align-center">
                  <span class="blocks bg_2 mr5"></span>
                  <span class="mr5">第二杯半价 30%</span>
                </div>
                <div class="flex align-center">
                  <span class="blocks bg_1 mr5"></span>
                  <span class="mr5">满100减10 30%</span>
                </div>
              </div>
            </div>
            <div>
              <div>
                <img src="../../../assets/images/product/win/chart_2.png" alt="" />
              </div>
              <div class="f16 fw600">占比统计</div>
              <div class="text999 mt20">
                <p>哪个产品是爆款?</p>
                <p>支付宝、微信哪个用的多?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 随时随地管理门店 -->
    <div class="bg-blue2">
      <div class="w1200 pt90 text-white textc">
        <div class="f40 mb40">随时随地管理门店</div>
        <div class="f16">
          <p>
            就算外出，打开浏览器登录后台、或者用手机即可管理门店，销售数据、库存信息、实时监
          </p>
          <p>控录像等尽在掌握</p>
        </div>
        <img
          src="../../../assets/images/product/win/model_6.png"
          alt=""
          class="mt50"
        />
      </div>
    </div>
    <!-- 多种功能，多种行业1键切换 -->
    <div class="bg-f9">
      <div class="w1200 pt90 textc">
        <div class="f40 mb40">多种功能，多种行业1键切换</div>
        <div class="f16">
          <p>
            支持零售、餐饮、服装、生活服务、母婴，无需单独下载软件，版本间一键切换
          </p>
          <p>无需反复安装。</p>
        </div>
        <div class="mt90 f16 flex align-center justify-center">
          <div class="textl">
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">营业报表</span>
            </div>
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">经销表</span>
            </div>
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">会员管理</span>
            </div>
          </div>
          <div class="textl ml180">
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">货流与供应链</span>
            </div>
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">原材料管控</span>
            </div>
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">员工管理</span>
            </div>
          </div>
          <div class="textl ml180">
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">网络商城</span>
            </div>
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">会员与CRM营销</span>
            </div>
            <div class="mb20">
              <img
                src="../../../assets/images/product/win/check.png"
                alt=""
                width="16"
                height="16"
                class="vm"
              />
              <span class="vm ml10">专属技术支持</span>
            </div>
          </div>
        </div>
        <img
          src="../../../assets/images/product/win/model_7.png"
          alt=""
          width="1000"
          class="mt50"
        />
      </div>
    </div>
    <!-- Windows智能硬件 适配各行各业 -->
    <div class="bg-white">
      <div class="w1100 pt90 pb60 textc">
        <div class="f40 mb40">Windows智能硬件 适配各行各业</div>
        <div class="hardwareBox flex flex-wrap pt30">
          <div class="hardwareItem textc transition" v-for="item in hardwareList" :key="item.id">
            <div class="imgbox">
              <img
                :src="item.imgUrl"
                alt=""
                class="transition"
              />
            </div>
            <div class="f18">{{item.tit}}</div>
            <div class="text999 f13 mt15 mb20">
             {{item.cont}}
            </div>
            <div class="f22">{{item.price}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 业绩起飞 从收银开始 -->
    <div class="bg-blue3 lastModel positionr">
        <div class="w1200 textc pt50">
            <img src="../../../assets/images/common/lastModel1.png" alt="" width="900">
            <div class="lastModel_cont positiona">
                <div class="flex flex-column align-center justify-center text-white hp100">
                    <div class="f38">业绩起飞 从收银开始</div>
                    <div class="f18 mt30">云蓝Windows收银系统</div>
                    <a href="https://pos.pluss.cn/resources/sdk/云蓝收银3.0.4.msi">
                      <div class="bg-white last_btn text-blue mt30 transition">免费下载</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
       hardwareList:[
         {
           id:1,
           imgUrl:require('../../../assets/images/product/win/win_1.png'),
           tit:'云蓝智能收银单屏机版',
           cont:'Intel J1800、4G内存、64G固态硬盘、防水、防油烟',
           price:'¥2260'
         },
         {
           id:2,
           imgUrl:require('../../../assets/images/product/win/win_2.png'),
           tit:'云蓝智能收银单屏一体机',
           cont:'Intel J1800、4G内存、64G固态硬盘、防水、防油烟',
           price:'¥2750'
         },
         {
           id:3,
           imgUrl:require('../../../assets/images/product/win/win_3.png'),
           tit:'云蓝智能收银单屏机版',
           cont:'Intel J1800、4G内存、64G固态硬盘、防水、防油烟',
           price:'¥2300'
         },
         {
           id:4,
           imgUrl:require('../../../assets/images/product/win/win_4.png'),
           tit:'云蓝智能收银双联屏机',
           cont:'Intel J2480、4G内存、64G固态硬盘、多点电容触摸屏、防水、防油烟',
           price:'¥2600'
         },
         {
           id:5,
           imgUrl:require('../../../assets/images/product/win/win_5.png'),
           tit:'云蓝智能收银双联屏',
           cont:'Intel J1800、4G内存、64G固态硬盘、多点电容触摸屏、防水、防油烟',
           price:'¥3340'
         },
         {
           id:6,
           imgUrl:require('../../../assets/images/product/win/win_6.png'),
           tit:'云蓝智能收银双联屏机',
           cont:'Intel J1800、4G内存、64G固态硬盘、多点电容触摸屏、防水、防油烟',
           price:'¥2260'
         },
         {
           id:7,
           imgUrl:require('../../../assets/images/product/win/win_7.png'),
           tit:'云蓝智能收银称重一体机单屏版',
           cont:'Intel J1800、4G内存、64G固态硬盘、多点电容触摸屏、防水、防油烟',
           price:'¥3160'
         },
         {
           id:8,
           imgUrl:require('../../../assets/images/product/win/win_8.png'),
           tit:'云蓝智能收银称重一体双屏机',
           cont:'Intel J1800、4G内存、64G固态硬盘、防水、防油烟',
           price:'¥2300'
         },
         {
           id:9,
           imgUrl:require('../../../assets/images/product/win/win_9.png'),
           tit:'云蓝智能收银自主一体机',
           cont:'Intel J1800、4G内存、64G固态硬盘、多点电容触摸屏、人脸识别、扫码支付、防水、防油烟',
           price:'¥5250'
         },
         {
           id:10,
           imgUrl:require('../../../assets/images/product/win/win_10.png'),
           tit:'云蓝智能收银单屏触屏版',
           cont:'Intel J2840、4G内存、64G固态硬盘、多点电容触摸屏、防水、防油烟',
           price:'¥2160'
         },
          {
           id:11,
           imgUrl:require('../../../assets/images/product/win/win_11.png'),
           tit:'云蓝智能收银双联屏机',
           cont:'Intel J2480、4G内存、64G固态硬盘、多点电容触摸屏、防水、防油烟',
           price:'¥3480'
         },
         {
           id:12,
           imgUrl:require('../../../assets/images/product/win/win_12.png'),
           tit:'云蓝智能自助收银台',
           cont:'Intel J1800、4G内存、64G固态硬盘、多点电容触摸屏、人脸识别、扫码支付、自动结算、防水、防油烟',
           price:'¥20000'
         }
       ]
    }
  }
};
</script>

<style  scoped>
.banner_contB {
  left: 0;
  top: 0;
  width: 100%;
}
.banner_cont {
  width: 638px;
}
.payItem {
  width: 130px;
  height: 42px;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #ffffff;
  padding: 0 5px;
  box-sizing: border-box;
}
.payItem:hover {
  transform: translateY(-5px);
}
.useItem {
  width: 360px;
  height: 252px;
  overflow: hidden;
}
.useItem:hover img {
  transform: scale(1.1);
}
.blocks {
  width: 20px;
  height: 10px;
  border-radius: 2px 2px 2px 2px;
}
.bg_1 {
  background-color: #0084ff;
}
.bg_2 {
  background-color: #b5dbff;
}
.charts_tit {
  height: 72px;
}
.line {
  width: 1px;
  height: 220px;
  background-color: #e6e6e6;
}
.hardwareBox .hardwareItem {
  width: 273px;
  padding: 20px;
  margin-right: 80px;
}
.hardwareBox .hardwareItem:nth-child(3n) {
  margin-right: 0;
}
.hardwareBox .hardwareItem .imgbox{
  height: 200px;
  overflow: hidden;
}
.hardwareBox .hardwareItem:hover {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.hardwareBox .hardwareItem:hover img {
  transform: scale(1.2);
}
.model{
  width: 100%;
}
.model .modelImg{
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>